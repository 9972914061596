// import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from "react";
import { Link } from "gatsby";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BlockContent from "./block-content";
import Container from "./container";

import styles from "./project.module.css";

function Project(props) {
  const { _rawBody, title, categories, mainImage, _rawExcerpt, relatedProjects } = props;
  console.log(mainImage);
  return (
    <article className={styles.root}>
      <Container>
        <div className={styles.grid}>
          {/* <div className={styles.mainContent}> */}
          <div className={styles.projectDescriptionWrapper}>
            <div className={styles.projectDescriptionFixed}>
              <h1 className={styles.title}>{title}</h1>
              {_rawExcerpt && <BlockContent blocks={_rawExcerpt || []} />}
            </div>
          </div>
          <div className={styles.mainWrapper}>
            {props.mainImage && mainImage.asset && (
              <div className={styles.mainImage}>
                <img src={imageUrlFor(buildImageObj(mainImage)).url()} alt={mainImage.alt} />
              </div>
            )}
            {_rawBody && <BlockContent blocks={_rawBody || []} />}
            {relatedProjects && relatedProjects.length > 0 && (
              <div className={styles.relatedProjects}>
                <h3 className={styles.relatedProjectsHeadline}>Related projects</h3>
                <ul>
                  {relatedProjects.map(project => (
                    <li key={`related_${project._id}`}>
                      {project.slug ? (
                        <Link to={`/project/${project.slug.current}`}>{project.title}</Link>
                      ) : (
                        <span>{project.title}</span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          {/* </div> */}
          {/* <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
              </div>
            )}
            {members && members.length > 0 && <RoleList items={members} title='Project members' />}
            {categories && categories.length > 0 && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside> */}
        </div>
      </Container>
    </article>
  );
}

export default Project;
